@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&family=Ubuntu:wght@300;400;500;700&display=swap");

@font-face {
	font-family: "Mona Sans";
	src: url("https://github.com/github/mona-sans/raw/main/fonts/web/MonaSans-Regular.woff2")
		format("woff2");
	font-weight: normal;
	font-style: normal;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Unbounded", "Mona Sans";
}

a {
	text-decoration: none;
	color: inherit;
	font-family: "Unbounded";
}

a:hover {
	color: #439aec;
}

.loader {
	width: fit-content;
	font-weight: bold;
	font-family: monospace;
	font-size: 30px;
	background: linear-gradient(90deg, #000 50%, #0000 0) right/200% 100%;
	animation: l21 2s infinite linear;
}
.loader::before {
	content: "Loading...";
	color: #0000;
	padding: 0 5px;
	background: inherit;
	background-image: linear-gradient(90deg, #fff 50%, #000 0);
	-webkit-background-clip: text;
	background-clip: text;
}

@keyframes l21 {
	100% {
		background-position: left;
	}
}
/* ================================================ */
.custom-header {
	font-weight: bold;
	background-color: #012630;
	color: #cfd0df;
	text-transform: uppercase;
}

.custom-cell {
	font-size: 14px;
	color: #006f7f;
}

.MuiDataGrid-root {
	border-radius: 8px;
	overflow: hidden;
}

.MuiDataGrid-columnHeaders {
	background: linear-gradient(135deg, #006f7f, #003f4f);
	color: white;
}
